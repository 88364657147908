import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Privacy Policy (May 25, 2018)',
  description: 'Uploadcare Privacy Policy (May 25, 2018) uses stored data only for internal processing. We never sell or otherwise give away the data. Please feel free to contact us if you have any questions about Uploadcare’s Privacy Policy.',
  header: 'Privacy Policy (May 25, 2018)',
  canonical: 'https://uploadcare.com/about/privacy-policy/',
  noindex: true
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Note: The previous version of our Privacy Policy can be found
`}<a parentName="p" {...{
        "href": "/about/privacy_policy_20170915/"
      }}>{`here`}</a>{`.`}</p>
    <p>{`Effective Date: May 25, 2018`}</p>
    <p>{`This Privacy Policy describes how we manage, process and store personal data
submitted in the context of providing our services. “Personal data” refers to
any information relating to an identifiable individual or their personal
identity.`}</p>
    <h2 {...{
      "id": "table-of-contents"
    }}><a parentName="h2" {...{
        "href": "#table-of-contents"
      }}>{`Table of Contents`}</a></h2>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#consent"
          }}>{`Consent`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#personal-data-collected"
          }}>{`Personal data collected`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#identity-and-contact-details"
            }}>{`Identity and contact details`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#data-protection-authority-declarations"
            }}>{`Data Protection Authority Declarations`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#data-collected-onthe-site"
            }}>{`Data collected on the site`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#purposes-ofprocessing-and-legal-basis"
            }}>{`Purposes of processing and legal basis`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#newsletter-and-marketing-emails"
            }}>{`Newsletter and marketing emails`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#testimonials"
            }}>{`Testimonials`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#third-party-disclosures"
            }}>{`Third party disclosures`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#your-data-protection-rights"
            }}>{`Your data protection rights`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#cookiestracking"
            }}>{`Cookies/Tracking`}</a></li>
        </ul>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#third-party-data"
          }}>{`Third party data`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#data-retention-periods"
          }}>{`Data retention periods`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#location-ofdata-storage-and-transfers"
          }}>{`Location of data storage and transfers`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#security"
          }}>{`Security`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#privacy-policy-changes"
          }}>{`Privacy policy changes`}</a></p>
      </li>
    </ul>
    <h2 {...{
      "id": "consent"
    }}><a parentName="h2" {...{
        "href": "#consent"
      }}>{`Consent`}</a></h2>
    <p>{`In subscribing to our services or filling in a contact form on our website
(uploadcare.com) or other sites owned by Uploadcare, you agree and accept that
we may gather, process, store and/or use the submitted personal data under the
rules set forth below.`}</p>
    <p>{`By giving your consent to us, you also retain the right to have your personal
data rectified, to be forgotten and/or to be erased.`}</p>
    <h2 {...{
      "id": "personal-data-collected"
    }}><a parentName="h2" {...{
        "href": "#personal-data-collected"
      }}>{`Personal data collected`}</a></h2>
    <h3 {...{
      "id": "identity-and-contact-details"
    }}><a parentName="h3" {...{
        "href": "#identity-and-contact-details"
      }}>{`Identity and contact details`}</a></h3>
    <p>{`Personal data are collected on our website by Uploadcare Inc., a company
registered under the laws of USA under number 82-1639831 with the Internal
Revenue Service of United States of America, and having its registered office at
2711 Centerville Road, Suite 400 City of Wilmington, County of New Castle,
19808, USA.`}</p>
    <h3 {...{
      "id": "data-protection-authority-declarations"
    }}><a parentName="h3" {...{
        "href": "#data-protection-authority-declarations"
      }}>{`Data Protection Authority Declarations`}</a></h3>
    <p>{`You retain the right to lodge any complaints on data protection with `}<a parentName="p" {...{
        "href": "http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm"
      }}>{`EU Data
Protection Authorities`}</a>{` for EU/EEA Data Subjects, and the
`}<a parentName="p" {...{
        "href": "https://www.edoeb.admin.ch/edoeb/en/home/the-fdpic/links/data-protection---switzerland.html"
      }}>{`Swiss Federal Data Protection and Information Commissioner`}</a>{` for
Swiss Data Subjects.`}</p>
    <h3 {...{
      "id": "data-collected-onthe-site"
    }}><a parentName="h3" {...{
        "href": "#data-collected-onthe-site"
      }}>{`Data collected on the site`}</a></h3>
    <p>{`When you subscribe to our services, the following data are collected and
managed: email, full name, company name, password, IP address.`}</p>
    <p>{`By using our services, the following data are collected and managed: log-on data
and browsing data where you authorize it, optional profile information,
subscription status, information that you downloaded to your storage (see
paragraph on third-party data below). Some data are collected automatically
by reason of your activity on the site (see paragraph on cookies below).`}</p>
    <h3 {...{
      "id": "purposes-ofprocessing-and-legal-basis"
    }}><a parentName="h3" {...{
        "href": "#purposes-ofprocessing-and-legal-basis"
      }}>{`Purposes of processing and legal basis`}</a></h3>
    <p>{`The principal purpose of collecting your personal data is to offer you a safe,
optimum, efficient, and personalized experience. To this end, you agree and
accept that we may use your personal data to:`}</p>
    <ul>
      <li parentName="ul">{`Provide our services and facilitate performance, including verifications
relating to you.`}</li>
      <li parentName="ul">{`Resolve any problems to improve the use of our site and services.`}</li>
      <li parentName="ul">{`Personalize, assess, and improve our services, content and materials.`}</li>
      <li parentName="ul">{`Analyze the volume and history of your use of our services;`}</li>
      <li parentName="ul">{`Inform you about our services as well as our partners’ services and/or
promotional offers;`}</li>
      <li parentName="ul">{`Prevent, detect, and investigate any activities that are potentially
prohibited, unlawful or contrary to good practice, and ensure compliance with
our `}<a parentName="li" {...{
          "href": "/about/terms/"
        }}>{`Terms of Service`}</a>{`.`}</li>
      <li parentName="ul">{`Comply with legal and regulatory obligations.`}</li>
    </ul>
    <p>{`We use the personal data submitted to us only in accordance with the applicable
data protection legislation. Our employees and third-party providers are under
an obligation to respect data privacy.`}</p>
    <h3 {...{
      "id": "newsletter-and-marketing-emails"
    }}><a parentName="h3" {...{
        "href": "#newsletter-and-marketing-emails"
      }}>{`Newsletter and marketing emails`}</a></h3>
    <p>{`For those of you that have expressly opted in to receive our Uploadcare
newsletter, you are easily able to unsubscribe by following the “unsubscribe”
links included in every email.`}</p>
    <p>{`Without systematically doing so, we may analyze and track the email click rates
(times you click on a link in an email) and open rates (the fact that you opened
an email) to assess performance rates on our mailouts you receive.`}</p>
    <h3 {...{
      "id": "testimonials"
    }}><a parentName="h3" {...{
        "href": "#testimonials"
      }}>{`Testimonials`}</a></h3>
    <p>{`Uploadcare publishes a list of Customers & Testimonials on its site with
information on customer names and job titles. Uploadcare undertakes to obtain
the authorization of every customer before publishing any testimonial on its
website (uploadcare.com).`}</p>
    <h3 {...{
      "id": "third-party-disclosures"
    }}><a parentName="h3" {...{
        "href": "#third-party-disclosures"
      }}>{`Third party disclosures`}</a></h3>
    <p>{`Personal data relating to you collected on our website are destined for
Uploadcare’s own use and may be forwarded to Uploadcare’s partner companies so
that we may obtain assistance and support in the context of carrying out our
services. Uploadcare ensures that it has in place clear data protection
requirements for all of its third-party providers.`}</p>
    <p>{`Uploadcare does not sell or rent your personal data to third parties for
marketing purposes whatsoever.`}</p>
    <p>{`In addition, Uploadcare does not disclose your personal data to third parties,
except if: (1) you (or your account administrator acting on your behalf)
requests or authorizes disclosure thereof; (2) the disclosure is required to
process transactions or supply services which you have requested (i.e. for the
purposes of processing an acquisition card with credit-card issuing companies);
(3) Uploadcare is compelled to do so by a government authority or a regulatory
body, in the case of a court order, a summons to appear in court or any other
similar requisition from a government or the judiciary, or to establish or
defend a legal application; or, (4) the third party is a subcontractor of
Uploadcare in the carrying out of services (for example: Uploadcare uses the
services of an internet provider or a telecommunications company).`}</p>
    <h3 {...{
      "id": "your-data-protection-rights"
    }}><a parentName="h3" {...{
        "href": "#your-data-protection-rights"
      }}>{`Your data protection rights`}</a></h3>
    <p>{`In accordance with the European General Data Protection Regulation 2016/679
(GDPR), you have a right of access, correction, and removal of your personal
data which you may exercise by sending us a support ticket
at `}<a parentName="p" {...{
        "href": "mailto:help@uploadcare.com"
      }}>{`help@uploadcare.com`}</a>{`. Your requests will be processed within
30 days. We may require your request to be accompanied by a photocopy of proof
of identity or authority.`}</p>
    <p>{`You are also able at any time to modify personal data by logging into your
account and clicking on “Account Settings.”`}</p>
    <h3 {...{
      "id": "cookiestracking"
    }}><a parentName="h3" {...{
        "href": "#cookiestracking"
      }}>{`Cookies/Tracking`}</a></h3>
    <p>{`As a general rule, Uploadcare uses cookies to improve and personalize its
website and/or measure its audience. Cookies are files saved to your local
storage when browsing on the internet and in particular on our site. A cookie is
not used to gather your personal data without your knowledge but instead to
record information on site browsing which can be read directly by Uploadcare on
your subsequent visits.`}</p>
    <p>{`You can choose to decline acceptance of all cookies, but your ability to browse
certain pages of the site may be reduced. The cookies used by Uploadcare are
intended to enable or facilitate communication, to enable the services requested
by users to be supplied, to recogniяe users when they re-visit the site, to
secure payments which users may make, or other preferences necessary for the
service requested to be supplied and to enable Uploadcare, internally, to carry
out analyses on hit rates and browsing experience so as to improve content,
to track email open rates, click rates, and bounce-back rates at individual
levels.`}</p>
    <p>{`By default, cookies are not installed automatically (except for those cookies
needed to run the site and Uploadcare’s services, and you are informed of their
installation by a clickable banner with a text description). In accordance with
the regulations that apply, Uploadcare will require your authorization before
implanting any other kind of cookie to your local storage. To avoid being
bothered by these routine requests for authorization and to enjoy uninterrupted
browsing, you can configure your device to accept Uploadcare cookies,
or we can remember your refusal or acceptance of certain cookies. By default,
browsers accept all cookies.`}</p>
    <p>{`When you access third party sites on our website, or when you are reading
integration or social media links, cookies can be created by the companies
disseminating these links. These third parties may be able to use cookies in the
context of Uploadcare’s services (partners or other third parties supplying
content or services available on the Uploadcare site) and are responsible for
the cookies they install, and it is their conditions on cookies which apply.
Uploadcare assumes no liability regarding the possible use of cookies by third
parties. For more information, you are advised to check the cookie policy
directly on these third-party sites concerning their use of cookies.`}</p>
    <h2 {...{
      "id": "third-party-data"
    }}><a parentName="h2" {...{
        "href": "#third-party-data"
      }}>{`Third party data`}</a></h2>
    <p>{`To provide our services, we capture and store some information about you and
users uploading materials to Uploadcare:`}</p>
    <ul>
      <li parentName="ul">{`IP Addresses`}</li>
      <li parentName="ul">{`Request Headers`}</li>
      <li parentName="ul">{`Data submitted to our API`}</li>
    </ul>
    <p>{`This data listed above are stored on secure servers. We don’t use data from
uploaded files for anything, except for:`}</p>
    <ul>
      <li parentName="ul">{`Detecting MIME-types`}</li>
      <li parentName="ul">{`Converting or modifying media upon request from their Account owner`}</li>
      <li parentName="ul">{`Delivering files and their modified versions via
our`}{` `}<abbr title="Content Delivery Network">{`CDN`}</abbr>{` upon request from
Account owner.`}</li>
    </ul>
    <p>{`You are easily able to recover your data from your Uploadcare account at any
time, by using our `}<a parentName="p" {...{
        "href": "/docs/api_reference/rest/"
      }}>{`REST API`}</a>{`. You may also modify and or
delete your data at any time from your account.`}</p>
    <p>{`In no case does Uploadcare sell, share or rent out your stored data to third
parties, nor does it use them for any purposes other than those outlined in this
Policy.`}</p>
    <p>{`Since you use Uploadcare services to get data from your users, you are
considered the data controller within the meaning of the GDPR, and Uploadcare is
acting as a data processor. In this capacity, you are responsible in particular
for:`}</p>
    <ul>
      <li parentName="ul">{`Making all the declarations necessary to the relative data protection
authority.`}</li>
      <li parentName="ul">{`Complying with all current regulations in force.`}</li>
      <li parentName="ul">{`Obtaining the explicit consent of the persons concerned when collecting their
personal data.`}</li>
      <li parentName="ul">{`Ensuring your authority to use the personal data collected in accordance with
the defined end purposes and refraining from any unauthorized use.`}</li>
    </ul>
    <p>{`If you use Uploadcare and are acting as a data controller/data processor when
handling EU citizens’ personal data, you want to make sure the whole data
pipeline is GDPR compliant. `}<a parentName="p" {...{
        "href": "/about/dpa/"
      }}>{`Here`}</a>{` you can find our updated
Data Processing Agreement that should be signed (if applicable) to ensure GDPR
compliance of data flows related to Uploadcare.`}</p>
    <h2 {...{
      "id": "data-retention-periods"
    }}><a parentName="h2" {...{
        "href": "#data-retention-periods"
      }}>{`Data retention periods`}</a></h2>
    <p>{`Uploadcare collects your personal data for the requirements of carrying out its
contractual obligations as well as information about how and when you use our
services, and we retain this data in active databases, log files or other types
of files so long as you use our services, and in accordance with the current
regulations in force.`}</p>
    <p>{`Uploadcare in no way undertakes to store all your data indefinitely. You can
access data so long as you hold an active account with us and for a period that
varies depending on the type of data concerned and the subscribed plan, but,
in no event no longer than 12 months after the closing of your account. The data
may be deleted at any time during active use of your account in accordance with
the provisions set forth above.`}</p>
    <h2 {...{
      "id": "location-ofdata-storage-and-transfers"
    }}><a parentName="h2" {...{
        "href": "#location-ofdata-storage-and-transfers"
      }}>{`Location of data storage and transfers`}</a></h2>
    <p>{`Uploadcare complies with the EU-U.S. Privacy Shield Framework and the Swiss-U.S.
Privacy Shield Frameworks as set forth by the U.S. Department of Commerce
regarding the collection, use, and retention of personal information transferred
from the European Union and Switzerland to the United States. Uploadcare has
certified to the Department of Commerce that it adheres to the Privacy Shield
Principles. If there is any conflict between the terms of this privacy policy
and the Privacy Shield Principles, the Privacy Shield Principles shall govern.
To learn more about the Privacy Shield program, and to view our certification,
please visit `}<a parentName="p" {...{
        "href": "https://www.privacyshield.gov/"
      }}>{`privacyshield.gov`}</a>{`. For more
information, see our `}<a parentName="p" {...{
        "href": "/about/privacy-shield-notice/"
      }}>{`Privacy Shield Notice`}</a>{`.`}</p>
    <h2 {...{
      "id": "security"
    }}><a parentName="h2" {...{
        "href": "#security"
      }}>{`Security`}</a></h2>
    <p>{`Within the framework of its services, Uploadcare attributes the very highest
importance to the security and integrity of its customers’ personal data.`}</p>
    <p>{`Thus and in accordance with the GDPR, Uploadcare undertakes to take all
pertinent precautions in order to preserve the security of the data and, in
particular, to protect them against any accidental or unlawful destruction,
accidental loss, corruption, unauthorized circulation or access, as well as
against any other form of unlawful processing or disclosure to unauthorized
persons.`}</p>
    <p>{`To this end, Uploadcare implements industry standard security measures to
protect personal data from unauthorized disclosure. In using industry
recommended methods of encoding, Uploadcare takes the measures necessary to
protect information connected with payments and credit cards.`}</p>
    <p>{`Moreover, to avoid in particular all unauthorized access, to guarantee accuracy
and the proper use of the data, Uploadcare has put the appropriate electronic,
physical and managerial procedures in place to safeguarding and preserving the
data gathered through its services.`}</p>
    <p>{`Notwithstanding this, there is no absolute safety from piracy or hackers. That
is why in the event a breach of security was to affect you, Uploadcare
undertakes to inform you thereof without undue delay and to use its best efforts
to take all possible measures to neutralize the intrusion and minimize the
impacts. Should you suffer any loss by reason of the exploitation by a third
party of a security breach, Uploadcare undertakes to provide you with every
assistance necessary, so that you can assert your rights.`}</p>
    <p>{`You should keep in mind that any user, customer or hacker who discovers and
takes advantage of a breach in security renders him or herself liable to
criminal prosecution and that Uploadcare will take all measures, including
filing a complaint and/or bringing court action, to preserve the data and the
rights of its users and of itself and to limit the impacts.`}</p>
    <h2 {...{
      "id": "privacy-policy-changes"
    }}><a parentName="h2" {...{
        "href": "#privacy-policy-changes"
      }}>{`Privacy policy changes`}</a></h2>
    <p>{`Uploadcare reserves the right to update this Privacy Policy at any time, in
particular pursuant to any changes made to the laws and regulations in force.
Any modifications made will be notified to you via our website or by email, to
the extent possible, fourteen (14) days at least before any changes come into
force.`}</p>
    <p>{`Please feel free to contact us if you have any questions about Uploadcare's
Privacy Policy or practices. You may contact our Data Protection Officer at
`}<a parentName="p" {...{
        "href": "mailto:privacyshield@uploadcare.com"
      }}>{`privacyshield@uploadcare.com`}</a>{` or at our mailing
address below:`}</p>
    <p>{`Uploadcare, Inc`}<br />{` 18801 Collins Ave 102-120`}<br />{` Sunny Isles Beach, FL
33160`}<br />{` USA`}</p>
    <p>{`For EU data subject you can send your requests to:`}</p>
    <p>{`“Uploadcare”, CIC Rotterdam, Netherlands`}<br />{` Groot Handelsgebouw`}<br />{`
Stationsplein 45, A4.004`}<br />{` 3013AK Rotterdam`}</p>
    <p>{`or to email: `}<a parentName="p" {...{
        "href": "mailto:gdpr@uploadcare.com"
      }}>{`gdpr@uploadcare.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      